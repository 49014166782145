import React, { Component } from "react";
import { Button, Form, Grid, Header, Image, Modal , CardContent, CardHeader} from "semantic-ui-react";
import { connect } from "react-redux";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import { formatQuery } from "../controllers/utilities/string-utils";
import { getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall } from "../components/lib/ad-manage-api-lib";
import { navigate } from "../../.cache/gatsby-browser-entry";
import { getUA } from "react-device-detect";
import "../styles/bg.css";
import { AdIosaregionSwipetoslide } from "../components/react-slick/ad-iosaregion-swipetoslide";
import { Link } from "gatsby";
import KaisakuApi from "../controllers/kaisaku-api";
import cookie from "react-cookies";
import coin from "../images/main/coin.png";
import imageUrl from "../images/fjbicon.png";
import zdlbg from "../images/main/zdlbg.png";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ready: false,
            userId: '',
            gameId: '',
            eventId: '',
            accessToken: '',
            nickname: '',
            createdAt: '',
            expireAt: '',
            expireAt_TTL: '',
            divStyle: '',
            maxCount: 0,
            saleCount: 0,
            transactionId: "",
            buyId: "",
            province: "安徽",
            city: "合肥",
            county: "長豐",
            provinces: ['安徽', '北京', '福建', '甘肅', '廣東', '廣西', '貴州', '海南', '河北', '河南', '黑龍江', '湖北', '湖南', '吉林', '江蘇', '江西', '遼寧', '內蒙古', '寧夏', '青海', '山東', '山西', '陜西', '上海', '四川', '天津', '西藏', '新疆', '雲南', '浙江', '重慶'],
            cities: ['合肥'],
            counties: ['長豐'],

            userName: "",
            userTel: "",
            userMail: "",

            provincesAddess: "",
            cityAddess: "",
            countyAddess: "",

            detailAddess: "",
            message: "",
            messageTop: "",
            mode: '',
            body: {},
            buttonCaption: "確定交易",

            itemAmount: 4888,
            amount: 0
        };
        let { userId, gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL, transactionId, buyId, mode } = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        this.state.transactionId = transactionId;
        this.state.buyId = buyId;
        this.state.mode = mode;

        const body = cookie.load("_body_data");
        if ((body) && (body !== "")) {
            this.state.body = body;
            console.log(body);
        }
    }

    async componentDidMount() {
        // 是否有销售数据,有显示,无,跳回游戏
        await this.getWallet(this.state.accessToken);
    }

    async getWallet(accessToken){
        this.setState({ ready: false });
        const res = await KaisakuApi.getWalletForM1(accessToken);
        this.setState({ amount: res.amount, ready: true });
    }

    render() {
        const productDescStyle = {
            margin: 0,
            padding: 0,
            height:"20",
            whiteSpace: "nowrap"
        };
        return (
            <Grid textAlign='center' style={{
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Modal
                        style={{ width: '289px', borderRadius: '10px 10px 10px 10px' }}
                        open
                    >
                        <div style={{
                            height: '32px', textAlign: 'left', fontSize: '14px', fontWeight: 'bold',
                            paddingTop: '8px', borderLeft:'1px solid #444',borderTop:'1px solid #444',borderRight:'1px solid #444',
                            borderRadius: '10px 10px 0px 0px',
                            backgroundColor:"#262626",
                            color:"#E4E4E4",
                            opacity: '1'
                        }}
                        >
                            <div style={{ color: '#E4E4E4', float: "left", textAlign: "left", paddingLeft: "10px" }}
                                onClick={() => {
                                    navigate(`/xr-saleing-page3${this.props.location.search}`);
                                }}
                            >返回</div>
                            <div style={{ color: 'red', float: "left", textAlign: "center", paddingLeft: "10px", width: "70%" }}>{this.state.messageTop}</div>
                            {(!this.state.mode) && (<div style={{ color: '#E4E4E4', float: "right", textAlign: "left", paddingRight: "10px" }}
                                onClick={() => {
                                    // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                    AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                }}
                            >回到遊戲</div>)}
                        </div>
                        <div style={{
                            width: '289px',
                            borderRadius: '0px 0px 0px 0px',
                            backgroundColor:"#262626",
                            display: ''
                        }}>
                            <div style={{ width: '289px', height: "60vh", overflowY: "auto", paddingTop: "30px", paddingLeft: "15px", paddingRight: "15px", fontsize:"16px", color: "#E4E4E4",                            borderLeft:'1px solid #444',borderRight:'1px solid #444', }}
                            >

<div style={{backgroundColor:"#FFF",width:"100%", borderRadius: '10px 10px 10px 10px',boxShadow: '0 1px 1px 0 #666, 0 0 0 1px #666',}}>
{((this.state.ready) || (this.state.amount > 0)) && (<div style={{paddingTop:"5px",color:"#E4E4E4",fontsize:"20px", fontWeight:"bold"}}>
<CardHeader style={{fontSize:"1.2em", width:"100%"}}>
                    <Header style={{textAlign:"left", width:"100%", marginTop: "0px", marginLeft:"2%"}} size="large">
                    <Image style={{
                        width:"1.45rem",
                        height:"1.45rem",
                        color:"#fff",
                        marginTop: "-5px"
                    }} centered src={coin}/>錢包餘額</Header>                    
                </CardHeader>
                <CardHeader style={{fontSize:"1.2em", width:"100%"}}>
                <Header color={"green"} 
                             style={{marginRight: 10, width:"100%", marginTop: "-5px",paddingLeft:"10px"}}>
                    
                    幣 {this.state.amount}                          
                             </Header>
                             </CardHeader>
                                </div>)}

                                <div style={{paddingTop:"20px",color:"#E4E4E4",fontWeight:"bold"}}>

                                <Grid style={{marginLeft:"5px"}}>
                    <Grid.Column style={{
                        padding: 5,
                        verticalAlign:"middle"
                    }} width={3}>
                        <Image fluid src={imageUrl}/>
                    </Grid.Column>
                                <Grid.Column width={13}>
                        <Grid style={{verticalAlign: "top"}}>
                            <Grid.Row style={{fontSize:`1em`, height:"30px", marginTop:"-10px"}}>
                                <Grid.Column width={10} textAlign={"left"} style={{height:"30px"}}>
                                    <Header style={productDescStyle}
                                            size={"medium"}>凜 電動飛機杯</Header>
                                </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{fontSize:`1em`, height:"30px", marginTop:"-10px"}}>
                                <Grid.Column width={6} textAlign={"left"} style={{height:"30px"}}>
                                    <Header style={productDescStyle} color={"green"}
                                            size={"medium"}>幣 4888</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>                                
                    </Grid>
                                </div>

    </div>                                

                                <div style={{ fontsize:"1em", paddingTop:"50px", width: "100%", color: "red", fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: decodeURI(this.state.message) }}></div>

<div style={{paddingTop:"50px"}}>
請確認你填寫內容，然後點確定交易<br /><br />

                                姓名 <br />
                                {this.state.body.userName}<br /><br />
                                地址 <br />
                                {this.state.body.provincesAddess} {this.state.body.cityAddess} {this.state.body.countyAddess}<br />
                                {this.state.body.detailAddess}<br /><br />

                                電話<br />
                                {this.state.body.userTel}<br /><br />
                                {(this.state.body.userMail) && (<>
                                    EMAIL<br />
                                    {this.state.body.userMail}<br /><br />
                                </>)}
</div>
                                

                                
                            </div>

                            <div style={{
                                height: '60px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold',
                                paddingTop: '1.2em', border: '0px',
                                borderRadius: '0px 0px 10px 10px',
                                backgroundImage: `url(${zdlbg})`, backgroundSize: '100% 100%',
                                marginTop: "-3px",
                                marginLeft: "-8px",
                                marginRight: "-8px",
                                marginBottom: "-10px",
                                color: "#FFF",
                                opacity: '1'
                            }}
                                onClick={async () => {
                                    if ((this.state.mode) && (this.state.buttonCaption === "返回")){
                                        navigate('/confirm-purchase');                                    
                                    }else
                                    if (this.state.buttonCaption === "返回遊戲") {                                        
                                        AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                    } else {
                                        let message = "";
                                        if (!this.state.body) {
                                            message += "請返回上一頁填寫必需填寫內容<br/>";
                                        }

                                        if (message) {
                                            console.log(message);
                                            this.setState({ message: message });
                                        } else {
                                            this.setState({ message: message });

                                            if (this.state.amount < this.state.itemAmount){
                                                message = "凜 電動飛機杯 需要 4888 幣，你的錢包餘額不足，請先充值。";
                                                this.setState({ message: message });
                                                await this.getWallet(this.state.accessToken);
                                                return;
                                            } 

                                            // 提交
                                            const body = this.state.body;
                                            console.log(body);
                                            const res = await KaisakuApi.confirmTransactionForM1(body, this.state.accessToken);
                                            if (res.status === 200) {
                                                const data = res.data;
                                                console.log(data);

                                                if (data.success === true) {
                                                    let buttonCaption = "返回遊戲";
                                                    if (this.state.mode){
                                                        buttonCaption = "返回";
                                                    }
                                                    this.setState({
                                                        message: "購買成功，請將頁面截圖紀錄。",
                                                        buttonCaption: buttonCaption
                                                    });
                                                    await this.getWallet(this.state.accessToken);
                                                } else {
                                                    let buttonCaption = "確定交易";
                                                    let message = '購買失敗';
                                                    if (data.message === "訂單已完成，請聯繫客服確認") {
                                                        message = "訂單已完成。如有疑問，請聯繫客服確認。";
                                                        buttonCaption = "返回遊戲";
                                                        if (this.state.mode){
                                                            buttonCaption = "返回";
                                                        }
                                                    }
                                                    this.setState({
                                                        message: message,
                                                        buttonCaption: buttonCaption
                                                    })
                                                }
                                            } else {
                                                let buttonCaption = "確定交易";
                                                let message = "購買失敗";
                                                if (res.data.message === "訂單已完成，請聯繫客服確認") {
                                                    message = "訂單已完成。如有疑問，請聯繫客服確認。";
                                                    buttonCaption = "返回遊戲";
                                                    if (this.state.mode){
                                                        buttonCaption = "返回";
                                                    }
                                                }
                                                this.setState({
                                                    message: message,
                                                    buttonCaption: buttonCaption
                                                })
                                                // alert("已售罄");
                                            }
                                        }
                                    }
                                }}
                            >
                                <span style={{ color: '#FFF' }}>{this.state.buttonCaption}</span>
                            </div>

                        </div>
                    </Modal>
                </Grid.Column>
            </Grid>
        );
    }
});